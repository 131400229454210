// import Vue from 'vue'
// import Router from 'vue-router' //vue2
import { createRouter, createWebHistory } from 'vue-router';
// import vueResource from 'vue-resource'

// SPA Routes
import HelloWorld from '@/components/HelloWorld';
import NotFound from '@/components/NotFound';
import Home from '@/components/Home';
import CreateVisa from '@/components/CreateVisa';
import TestFB from '@/components/TestFB';
import TestSTP from '@/components/TestSTP';
import CheckoutSuccess from '@/components/CheckoutSuccess';
import ThankYou from '@/components/ThankYou';
// import Http from '@/components/Http'
// import TestFirebase from '@/components/TestFirebase'
// import TestStripe from '@/components/TestStripe'

// Vue.use(Router)
// Vue.use(vueResource)

// Vue.http.options.root = 'https://vue-visa.firebaseio.com/'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/helloworld',
    name: 'helloworld',
    component: HelloWorld,
  },
  {
    path: '/createvisa',
    name: 'createvisa',
    component: CreateVisa,
  },
  {
    path: '/testfb',
    name: 'testfb',
    component: TestFB,
  },
  {
    path: '/teststp',
    name: 'teststp',
    component: TestSTP,
  },
  {
    path: '/checkoutsuccess',
    name: 'checkoutsuccess',
    component: CheckoutSuccess,
  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: ThankYou
  },
  // {
  //   path: '/http',
  //   name: 'http',
  //   component: Http
  // },
  // {
  //   path: '/testfirebase',
  //   name: 'testfirebase',
  //   component: TestFirebase
  // },
  // {
  //   path: '/teststripe',
  //   name: 'teststripe',
  //   component: TestStripe
  // },
  // catch all redirect
  // plan on directly navigating to the not-found route using its name
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  { path: '/:pathMatch(.*)', name: 'bad-not-found', component: NotFound },
];
const scrollBehavior = (to, from, savedPosition) => {
  return { top: 0, left: 0 }; //always top
  // return savedPosition || { top: 0, left: 0 }; //last position
};
export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior
});
