<template>
  <section id="imageCropper">
    <label>Upload Photo: <i class="fa-regular fa-circle-question"></i></label>
    <div class="ic-box">
      <cropper
        ref="cropper"
        class="cropper upload-example-cropper"
        :src="childPhotos.input"
        :stencil-props="{
          aspectRatio: 6 / 8,
          movable: true,
          resizable: true,
          minWidth: '100px',
          previewClass: 'preview',
        }"
        @change="change"
      />
      <div class="croppedImage" v-if="childPhotos.input" v-show="viewCropped">
        <img :src="childPhotos.output" />
      </div>
    </div>
    <div class="button-wrapper error" v-show="!childPhotos.input">
      <input
        v-show="!childPhotos.input"
        id="fileUpload"
        ref="fileUpload"
        type="file"
        @change="loadImage($event)"
        accept="image/*"
      />
    </div>
    <div class="button-wrapper" v-show="childPhotos.input">
      <div class="bw-actions">
        <button class="btn btn-secondary" @click.stop.prevent="toggleCropped">
          <span v-if="!viewCropped">View Crop</span
          ><span v-if="viewCropped">Edit Photo</span>
        </button>
        <button class="btn btn-black" @click.stop.prevent="resetPhoto">
          <i class="fa-solid fa-trash" />
        </button>
      </div>
    </div>
    <div class="cropper-error" v-show="!childPhotos.input">
      Image is required. Please upload a recent photo.
    </div>
  </section>
</template>

<script>
// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

// Imports
import { Cropper, CircleStencil } from "vue-advanced-cropper";

export default {
  components: {
    //   moment
    Cropper,
    CircleStencil,
  },
  props: ["photos", "doPhotosValidation"],
  name: "imagecropper",
  data() {
    return {
      childPhotos: {
        input: this.photos.input,
        coordinates: this.photos.coordinates,
        output: this.photos.output,
        inputType: null,
      },
      viewCropped: false,
    };
  },
  methods: {
    ///Cropper
    change({ coordinates, canvas }) {
      // console.log(coordinates, canvas);
      this.childPhotos.coordinates = coordinates;

      //create crop
      // const { cropped } =  this.$refs.cropper.getResult();
      // cropped.toBlob((blob) => {
      //   // Do something with blob: upload to a server, download and etc.
      //   this.personal.photos.output = blob;
      // }, this.childPhotos.imageType);
      this.childPhotos.output = canvas.toDataURL('image/jpeg', 1.0);
      if (canvas) {
        this.$emit("childValid", true);
      }
    },
    resetPhoto() {
      this.viewCropped = false;
      this.childPhotos = {
        input: null,
        coordinates: null,
        output: null,
        inputType: null,
      };
      document.getElementById("fileUpload").value = "";
      this.$emit("childValid", false);
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.childPhotos.input) {
          URL.revokeObjectURL(this.childPhotos.input);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.childPhotos = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            input: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            inputType: getMimeType(e.target.result, files[0].type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },
    toggleCropped() {
      this.viewCropped = !this.viewCropped;
    },
    defaultPosition() {
      console.log("set: defaultPos");
      return {
        left: this.childPhotos.coordinates.left,
        top: this.childPhotos.coordinates.top,
      };
    },
    resize(width, height, left, top) {
      this.$refs.cropper.setCoordinates({
        width: width,
        height: height,
        left: left,
        top: top,
      });
    },
    // Form
    readFileAs64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file); //does conversion to base64
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        // reader.readAsArrayBuffer(file);
      });
    },
    async onSubmit(values) {
      console.log("CHILD-Photos: form success");

      let exportObj = this.childPhotos;

      // console.log(exportObj.input);

      // input = blob, convert to (base64)
      if (exportObj.input.includes("blob")) {
        console.log("do blob conversion");
        console.log(exportObj.input);
        // grab directly from input box
        let temp = null;
        try {
          let file = this.$refs.fileUpload.files[0];
          temp = await this.readFileAs64(file);
          // console.log(temp);
          exportObj.input = temp;
        } catch (err) {
          console.log(err);
        }

        // console.log('newtemp:',exportObj.input);
        this.$emit("childValid", true);
        this.$emit("updatePhotos", exportObj);
        return;
      }

      this.$emit("childValid", true);
      this.$emit("updatePhotos", exportObj);
    },
  },
  watch: {
    doPhotosValidation: function () {
      // console.log('child transport watch: validate reference...')
      if (this.childPhotos.input) {
        this.onSubmit();
      } else {
        this.$emit("childValid", false);
      }
    },
  },
  created() {
    if (this.photos.coordinates) {
      console.log("coord exist");
      setTimeout(() => {
        // this.resize(209,372,160,197);
        let coords = this.photos.coordinates;
        this.resize(coords.width, coords.height, coords.left, coords.top);
      }, 1000);
    }
  },
  unmounted() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.childPhotos.input) {
      URL.revokeObjectURL(this.childPhotos.input);
    }
  },
};
</script>

<style lang="scss">
#imageCropper {
  max-width: 300px;
}
.ic-box {
  position: relative;
  display: block;
}
.cropper {
  aspect-ratio: 6 / 8;
  width: 100%;
  background: url(~@/assets/visa_mask.png) no-repeat center #ddd;
  background-size: auto auto;
}
.cropper-error {
  margin-top: 0.2rem;
  font-size: 0.875em;
  font-weight: 400;
}
.croppedImage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #3fb37f;
  width: 100%;
  height: 100%;
  img {
    aspect-ratio: 6 / 8.1;
    width: 100%;
  }
}
// .preview {
//   background: url(~@/assets/visa_mask.png) no-repeat center #ddd;
//   background-size: auto auto;
// }
.button-wrapper {
  color: #fff;
  padding: 1rem;
  background: #3fb37f;
  > .bw-actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &.error {
    background: rgb(231, 83, 83);
  }
}
button > input[type="file"] {
  display: block;
  opacity: 0.5;
}
</style>
