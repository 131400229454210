<template>
  <section>
    <Form id="formCreate3-2-1" @submit="onSubmit" @invalid-submit="onInvalidSubmit" :validation-schema="schema">
      <Field name="entryPort" :rules="schema.entryPort" class="form-control" v-slot="{ field, meta }" v-model="childTravel.entryPort">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="entryPort">Entrance Port (Air)</label>
            <select v-bind="field" class="form-control">
              <option value="">Select an Entry Port</option>
              <option v-for="port in entryPorts" :key="port.id" :value="port.id" :disabled="!port.active">{{port.name}}</option>
            </select>
            <ErrorMessage name="purpose" class="form-control-feedback small" />
        </div>
      </Field>
      <div class="row">
        <div class="col-6">
          <Field name="airOrigin" :rules="schema.airOrigin" class="form-control" v-slot="{ field, meta }" v-model="childTravel.airOrigin">
            <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                <label for="airOrigin">Flight Origin (Country)</label>
                <input v-bind="field" class="form-control" />
                <ErrorMessage name="airOrigin" class="form-control-feedback small" />
            </div>
          </Field>
        </div>
        <div class="col-6">
          <Field name="airFlight" :rules="schema.airFlight" class="form-control" v-slot="{ field, meta }" v-model="childTravel.airFlight">
            <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                <label for="airFlight">Flight Number</label>
                <input v-bind="field" class="form-control" />
                <ErrorMessage name="airFlight" class="form-control-feedback small" />
            </div>
          </Field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <Field name="entryDate" :rules="schema.entryDate" class="form-control" v-slot="{ field, meta }" v-model="childTravel.entryDate">
            <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                <label for="entryDate">Entry Date (mm/dd/yyyy)</label>
                <input v-bind="field" class="form-control" type="date" :min="dateHelper.approvalDate" />
                <ErrorMessage name="entryDate" class="form-control-feedback small" />
            </div>
          </Field>
        </div>
        <div class="col-6">
          <Field name="exitDate" :rules="schema.exitDate" class="form-control" v-slot="{ field, meta }" v-model="childTravel.exitDate">
            <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                <label for="exitDate">Exit Date (mm/dd/yyyy)</label>
                <input v-bind="field" class="form-control" type="date" :min="dateHelper.approvalDate" />
                <ErrorMessage name="exitDate" class="form-control-feedback small" />
            </div>
          </Field>
        </div>
      </div>
      <Field name="exitType" :rules="schema.exitType" class="form-control" v-slot="{ field, meta }" v-model="childTravel.exitType">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="exitType">Exit Transportation</label>
            <select v-bind="field" class="form-control">
              <option value="">Select exit type</option>
              <option v-for="etype in entryTypes" :key="etype.id" :value="etype.id">{{etype.name}}</option>
            </select>
            <ErrorMessage name="purpose" class="form-control-feedback small" />
        </div>
      </Field>
      <button class="btn btn-primary invisible" type="submit" id="btnChildSubmit">CHILD SUBMIT</button>
    </Form>
  </section>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    Field,
    Form,
    ErrorMessage
  },
  props: [
    'travel',
    'entryTypes',
    'isTravelValid',
    'entryPorts'
  ],
  name: 'travelair',
  data() {
    return {
      childTravel: {
        entryPort: this.travel.entryPort,
        airOrigin: this.travel.airOrigin,
        airFlight: this.travel.airFlight,
        entryDate: this.travel.entryDate,
        exitDate: this.travel.exitDate,
        exitType: this.travel.exitType,
      },
      dateHelper: {
        approvalDate: moment(Date.now()).add(3,'days').format('YYYY-MM-DD'),
      },
    }
  },
  methods: {
    onSubmit(values) {
      console.log('CHILD-Trns: form success')
      this.$emit('childValid', true)
      this.$emit('updateTransport', this.childTravel)
    },
    onInvalidSubmit({ values, errors, results }) {
      // console.log(values); // current form values
      // console.log(errors); // a map of field names and their first error message
      // console.log(results); // a detailed map of field names and their validation results
      // console.log('CHILD-Trns: invalid form submission. fix errors and retry again.');
      this.$emit('childValid', false)
    },
  },
  watch: {
    'isTravelValid': function(){
      // console.log('child transport watch: validate reference...')
      document.getElementById("btnChildSubmit").click(); //triggers child -> submit
    }
  },
  computed: {
    schema(that=this) {
      return yup.object({
        entryPort: yup.string().required().label('Entry port'),
        exitType: yup.string().required().label('Exit type'),
        airOrigin: yup.string().min(2).label('Flight origin'),
        airFlight: yup.string().min(2).label('Flight number'),
        entryDate: yup.date().typeError('Invalid date').test(
          "entry_test",
          "Entry date must be in the future.",
          function (value) {
            return moment(value) >  moment();
          }
        ).label('Entry date'),
        exitDate: yup.date().typeError('Invalid date').test(
          "exit_test",
          "Exit date must be in the future.",
          function (value) {
            return moment(value) >  moment();
          }
        ).label('Exit date'),

      });
    },
  }
}
</script>
