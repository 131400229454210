<template>
  <footer>
    <div class="container">
      <div class="row ft-navigation">
        <div class="col">
          <img src="../assets/ve-partner.svg" class="ve-logo">
        </div>
        <div class="col">
          <div class="title">Visa Express</div>
          <ul class="list-footer">
            <router-link to="/helloworld">
              <li><span>E-Visa Requirements</span></li>
            </router-link>
            <li><span>Check E-visa Status</span></li>
            <li><span>About Us</span></li>
            <li><span>Contact Us</span></li>
          </ul>
        </div>
        <div class="col">
          <div class="title">Lao Travel</div>
          <ul class="list-footer">
            <li><span>General FAQ's</span></li>
            <li><span>Travel Maps</span></li>
            <li><span>Business Arrangements</span></li>
            <li><span>Top Travel Destinations</span></li>
          </ul>
        </div>
        <div class="col">
          <div class="title">News &amp; Events</div>
          <ul class="list-footer">
            <li><span>Latest News</span></li>
            <li><span>Upcoming Events</span></li>
          </ul>
        </div>
        <div class="col">
          <div class="title">Partner Sites</div>
          <ul class="list-footer">
            <li><span>Lao PDR Embassy</span></li>
            <li><span>HukJao Tours</span></li>
            <li><span>Lao Vacation Rentals</span></li>
          </ul>
        </div>
      </div>
      <div class="row ft-bottom">
        <div class="col-sm-12">
          <div class="help">Having trouble?: Speak with an e-visa agent, call <span>1-888-LAO-VISA</span>, Lao and English speaking reps available.</div>
          <div class="end">
            Copyright © 2017 VisaExpress LLC. All rights reserved.&nbsp;&nbsp;&nbsp;&nbsp;
            <ul class="list-end">
              <li><span>Privacy Policy</span></li>
              <li><span>Terms of Use</span></li>
              <li><span>Sales and Refunds</span></li>
              <li><span>Legal</span></li>
              <li><span>Site Map</span></li>
            </ul>

          </div>
        </div>
      </div>
    </div>

<!--     <br>
    <button @click="modalOpen = true">Toogle Action</button>
    <br> -->
    <!-- <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <modal v-model="modalOpen">
        <div name="modal-header" class="modal-header">
          <h4 class="modal-title">
            <i>Custom</i> <code>Modal</code> <b>Title</b>
          </h4>
        </div>
        <div name="modal-body" class="modal-body">
          <h3>Hey it works</h3>
        </div>
        <div name="modal-footer" class="modal-footer">
          <button type="button" class="btn btn-default" @click="modalOpen = false">Exit</button>
          <button type="button" class="btn btn-success" @click="modalOpen = false">Custom Save</button>
        </div>
      </modal>
    </transition> -->




  </footer>
</template>

<script>
import { alert, modal } from 'vue-strap'

export default {
  components: {
    alert,
    modal
  },
  data(){
    return {
      modalOpen: false
    }
  },
  methods: {
    toggleAlert() {
      // this.showRight = (this.showRight === 'true') ? this.showRight = this.showRight : this.showRight = !this.showRight;
    }
  },
  created() {
    console.log('footer created')
  }
}
</script>

<style lang="scss" scoped>
$blue: blue;
footer {
  color: $blue;
}
</style>
