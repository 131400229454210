<template>
  <section class="container" id="mainCreate">
      <div class="row justify-content-md-center justify-content-lg-center mb-4">
        <div class="col-sm-12 col-md-10 col-lg-10">
          <div class="timeline">
            <div class="line"></div>
            <div class="step step--1" :class="{'step--active': currentTimelineStep == '1'}">
              <div class="s-cir"><i class="fa-solid fa-user"></i></div>
              <div class="s-text">Personal Info</div>
            </div>
            <div class="step step--2" :class="{'step--active': currentTimelineStep == '2'}">
              <div class="s-cir"><i class="fa-solid fa-address-card"></i></div>
              <div class="s-text">Passport Info</div>
            </div>
            <div class="step step--3" :class="{'step--active': currentTimelineStep == '3'}">
              <div class="s-cir"><i class="fa-solid fa-plane-up"></i></div>
              <div class="s-text">Travel Info</div>
            </div>
            <div class="step step--4" :class="{'step--active': currentTimelineStep == '4'}">
              <div class="s-cir"><i class="fa-solid fa-eye"></i></div>
              <div class="s-text">Verify Data</div>
            </div>
            <div class="step step--5" :class="{'step--active': currentTimelineStep == '5'}">
              <div class="s-cir"><i class="fa-solid fa-cart-shopping"></i></div>
              <div class="s-text">Online Payment</div>
            </div>
          </div>
        </div>
      </div>
      <transition
        enter-active-class="animated fadeInRight"
        leave-active-class="animated fadeOutLeft"
      >
        <component :is="currentStep" />
      </transition>

      <!-- User Helpers -->
      <transition
        enter-active-class="animated fadeInRight"
        leave-active-class="animated fadeOutRight"
      >
        <alert placement="top-right" type="danger" width="400px" v-if="alertOpen">
          <button class="close small" @click="alertOpen = false">x</button>
          <strong>Invalid Data!</strong>
          <p>There seems to be some errors with your data, please fix them before moving on to the next step.</p>
        </alert>
      </transition>


  </section>
</template>

<script>
import VisaForm1 from '@/components/create/VisaForm1'
import VisaForm2 from '@/components/create/VisaForm2'
import VisaForm3 from '@/components/create/VisaForm3'
import VisaForm4 from '@/components/create/VisaForm4'
import VisaForm5 from '@/components/create/Checkout'

import { alert, modal } from 'vue-strap'


export default {
  components: {
    'app-visaform1': VisaForm1,
    'app-visaform2': VisaForm2,
    'app-visaform3': VisaForm3,
    'app-visaform4': VisaForm4,
    'app-visaform5': VisaForm5,
    alert,
    modal
  },
  name: 'createvisa',
  data() {
    return {
      alertOpen: false,
      goBack: false,
      // currentStep: this.$store.state.visaStep
      allowEdit: false,
      isVerify: false //use to change buttons to save / cancel when user is at checkout and wants to change data    
    }
  },
  methods: {
    // scrollToTop(){
    //   console.log('do smooth scroll')
    //   document.body.scrollTop
    // }
  },
  computed: {
    currentStep() {
      // this.scrollToTop() //TODO
      return this.$store.state.visaStep
    },
    currentTimelineStep() {
      var val = this.$store.state.visaStep.replace('app-visaform', '')
      return Number(val)
    }
  }
}
</script>

<style scoped>

</style>
