<template>
  <div id="thankYou">
    <section class="container" v-if="showThanks == true">
      <div class="row justify-content-lg-center">
        <div class="col-sm-12 col-lg-10">
          <div class="heading">Application Submission Successful</div>
          <p>Dear [Name],</p>
          <p>Thank you for using VisaExpress.com. Your e-visa has been submitted and sent to the approval department. Please wait up to 3 business days for completion. Upon approval or denial, you will be notified via email with further instructions.</p>
          <p> You will receive an email shortly with an application reference number. Come back to our website at any time to <a href="#">check the status of your application</a>, or to print out any approved e-visa documentation.</p>
          <p>&nbsp;</p>
          <p><b>Best Regards,</b><br>VisaExpress Staff</p>
          <p>&nbsp;</p>
          <p class="text-muted">P.S. : Feel free to use the links in the footer of our website to learn more about the country of Laos, it's people or to make future plans for your upcoming travel.</p>
        </div>
      </div>
    </section>
    <section class="container" v-if="showThanks == false">
      <div class="row justify-content-lg-center">
        <div class="col-sm-12 col-lg-10">
          <div class="heading">Sorry, You do not have acess to this page</div>
          <p>&nbsp;</p>
          <p>You will be redirect shortly...</p>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
export default {
  components: {
  },
  name: 'thankyou',
  data() {
    return {
      showThanks: this.$store.state.showThanks
    }
  },
  methods: {
    sendConfirmationEmail() {
      console.log('do confirmation email')
    }
  },
  computed: {

  },
  created: function() {
    var that = this
    if (this.showThanks == true) {

      //A1) (change visaform state to 1)
      this.$store.state.visaStep = 'app-visaform1'
      //A2) (do email inside thank you page)
      //this.sendConfirmationEmail

      //... show page


    } else {
      //redirect to homepage
      setTimeout(function(){
        that.$router.replace('/')
      }, 5000)

    }
  },
  unmounted() {
    this.$store.state.showThanks = false
  }
}
</script>


<style scoped lang="scss">

</style>
