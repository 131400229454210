<template>
  <div class="container" style="min-height: 40rem">
    <div class="row">
      <div class="col-12 mt-4">
        <div class="btn btn-primary" @click="submit">Submit Checkout</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mt-4 mb-4">
        <div class="card p-4">
          <h6><strong>Make a payment</strong></h6>
          <form id="payment-form" @submit.prevent="stripeSubmit">
            <div class="form-group">
              <label for="paymentName">Name</label>
              <input
                type="text"
                class="form-control"
                id="paymentName"
                aria-describedby="nameHelp"
              />
              <small id="nameHelp" class="form-text text-muted"
                >Full name, first and last.</small
              >
            </div>
            <div class="form-group">
              <label for="paymentEmail">Email address</label>
              <input
                type="email"
                class="form-control"
                id="paymentEmail"
                aria-describedby="emailHelp"
              />
              <small id="emailHelp" class="form-text text-muted"
                >We'll never share your email with anyone else.</small
              >
            </div>
            <div class="form-group">
              <label for="paymentCard">Payment Card</label>
              <div class="card card-bkg">
                <div id="card-element">
                  <!-- Elements will create input elements here -->
                </div>
              </div>
              <small id="emailHelp" class="form-text text-muted">
                <div id="card-errors" role="alert"></div>
              </small>
            </div>

            <!-- We'll put the error messages in this element -->
            <button type="submit" class="btn btn-primary mt-4">Pay</button>
          </form>
        </div>
      </div>
      <div class="col-md-6 mt-4 mb-4">
        <div
          class="card p-4 card-msgs"
          id="messages"
          role="alert"
        >
          <h6><strong>Messages:</strong></h6>
          <div id="messages" v-html="stripeMessages"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//publishable key
// import Stripe from 'stripe';

export default {
  components: {},
  data() {
    return {
      test: {},
      stripe: "",
      cardElement: "",
      stripeMessages: "",
    };
  },
  methods: {
    async submit() {
      const url = process.env.VUE_APP_BACKEND_URL + "/payment-link";
      const meta = {
        uuid: this.$store.state.userData.uuid,
        qty: 1,
        email: this.$store.state.userData.personal.email,
      };
      let data;
      console.log(meta);
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(meta),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          window.location.href = data.url;
        })
        .catch(function () {
          console.log("error");
        });
    },
    addMessage(message) {
      const newMsg = "> " + message + "<br/>";
      this.stripeMessages += newMsg;
      console.log("StripeSampleDebug:", message);
    },
    setUpStripe() {
      if (window.Stripe === undefined) {
        alert("Stripe V3 library not loaded!");
      } else {
        const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY); //test-mode
        // const stripe = window.Stripe("pk_live_vHLFv4kPpls7bhiIaQBlEwur"); //live-mode
        this.stripe = stripe;

        const elements = stripe.elements();
        const style = {
          base: {
            color: "#32325d",
          },
        };
        // this.cardCvc = elements.create('cardCvc');
        // this.cardExpiry = elements.create('cardExpiry');
        // this.cardNumber = elements.create('cardNumber');

        // this.cardCvc.mount('#card-cvc');
        // this.cardExpiry.mount('#card-expiry');
        // this.cardNumber.mount('#card-number');

        const cardElement = elements.create("card", { style: style });
        cardElement.mount("#card-element");
        this.cardElement = cardElement;
        cardElement.on("change", ({ error }) => {
          let displayError = document.getElementById("card-errors");
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = "";
          }
        });
        // this.listenForErrors();
      }
    },
    async stripeSubmit() {
      // Create PaymentIntent on the server
      const backendSubmitUrl = process.env.VUE_APP_BACKEND_URL + "/create-payment-intent";
      const { error: backendError, clientSecret } = await fetch(
        backendSubmitUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodType: ["card"],
            currency: "usd",
            uuid: '123456789',
          }),
        }
      ).then((res) => res.json());
      if (backendError) {
        this.addMessage(backendError.message);
        return;
      }

      this.addMessage("PaymentIntent created!");

      // Tokenize and process payment => sends back confirmation
      const nameInput = document.querySelector("#paymentName");
      const emailInput = document.querySelector("#paymentEmail");
      const { error: stripeError, paymentIntent } =
        await this.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: this.cardElement,
            billing_details: {
              name: nameInput.value,
              email: emailInput.value,
            },
          },
        });
      if (stripeError) {
        this.addMessage(stripeError.message);
        alert(stripeError.message + ' Fix errors and try again.')
      }
      const successMsg =
        "PaymentIntent (" + paymentIntent.id + "): " + paymentIntent.status;
      this.addMessage(successMsg);
    },
  },
  created() {},
  mounted() {
    this.setUpStripe();
  },
};
</script>

<style>
.card-bkg {
  background-color: #efefef;
  padding: 1rem;
}
.card-msgs {
  background-color: #333; color: yellow
}
</style>