<template>
  <form id="formCreate4" v-on:submit.prevent>
    <div class="row justify-content-md-center justify-content-lg-center">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <div class="card card--formbox">
          <div class="card-header">
            <h3>Verify your data</h3>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col-sm-12">
                <h3>Personal Data <button class="btn btn-danger float-right" @click="editPersonal"> Edit</button></h3>
                <div class="box-data">
                  <table class="table tbl-verify">
                    <tbody>
                      <tr>
                        <th scope="row">Photo</th>
                        <td><img :src="userData.personal.photos.output" class="uploaded-image"></td>
                      </tr>
                      <tr>
                        <th scope="row">First Name</th>
                        <td>{{userData.personal.firstName}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Last Name</th>
                        <td>{{userData.personal.lastName}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Date of birth</th>
                        <td>{{userData.personal.dob}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Birth Place</th>
                        <td>{{userData.personal.birthPlace}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Nationality</th>
                        <td>{{userData.personal.nationality}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Occupation</th>
                        <td>{{userData.personal.occupation}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Email</th>
                        <td>{{userData.personal.email}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- {{userData.personal}} -->
                <p>&nbsp;</p>
                <h3>Passport Data  <button class="btn btn-danger float-right" @click="editPassport"> Edit</button></h3>
                <div class="box-data">
                  <table class="table tbl-verify">
                    <tbody>
                      <tr>
                        <th scope="row">Passport Country</th>
                        <td>{{userData.passport.passportCountry}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Passport Number</th>
                        <td>{{userData.passport.passportNumber}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Passport Issue Place</th>
                        <td>{{userData.passport.passportIssuePlace}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Passport Issue Date</th>
                        <td>{{userData.passport.passportIssueDate}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Passport Expiration Date</th>
                        <td>{{userData.passport.passportExpiration}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Address</th>
                        <td>{{userData.passport.address1}}</td>
                      </tr>
                      <tr v-show="userData.passport.address2">
                        <th scope="row">Address (continued)</th>
                        <td>{{userData.passport.address2}}</td>
                      </tr>
                      <tr>
                        <th scope="row">City</th>
                        <td>{{userData.passport.city}}</td>
                      </tr>
                      <tr>
                        <th scope="row">State</th>
                        <td>{{userData.passport.state}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Zipcode</th>
                        <td>{{userData.passport.zipcode}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- {{userData.passport}} -->
                <p>&nbsp;</p>
                <h3>Travel Data  <button class="btn btn-danger float-right" @click="editTravel"> Edit</button></h3>
                <div class="box-data">
                  <table class="table tbl-verify">
                    <tbody>
                      <tr>
                        <th scope="row">Purpose of Travel</th>
                        <td>{{userData.travel.purpose}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Entry Type</th>
                        <td>{{userData.travel.entryType}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Exit Type</th>
                        <td>{{userData.travel.exitType}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Entry Port</th>
                        <td>{{userData.travel.entryPort}}</td>
                      </tr>
                      <tr v-show="userData.travel.exitPort">
                        <th scope="row">Exit Port</th>
                        <td>{{userData.travel.exitPort}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Entry Date</th>
                        <td>{{userData.travel.entryDate}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Exit Date</th>
                        <td>{{userData.travel.exitDate}}</td>
                      </tr>
                      <tr v-show="userData.travel.airOrigin">
                        <th scope="row">Air Origin</th>
                        <td>{{userData.travel.airOrigin}}</td>
                      </tr>
                      <tr v-show="userData.travel.airFlight">
                        <th scope="row">Air Flight Number</th>
                        <td>{{userData.travel.airFlight}}</td>
                      </tr>
                      <tr v-show="userData.travel.refFirst">
                        <th scope="row">Reference: First</th>
                        <td>{{userData.travel.refFirst}}</td>
                      </tr>
                      <tr v-show="userData.travel.refLast">
                        <th scope="row">Reference: Last</th>
                        <td>{{userData.travel.refLast}}</td>
                      </tr>
                      <tr v-show="userData.travel.refAddress">
                        <th scope="row">Reference: Address</th>
                        <td>{{userData.travel.refAddress}}</td>
                      </tr>
                      <tr v-show="userData.travel.refPhone">
                        <th scope="row">Reference: Phone</th>
                        <td>{{userData.travel.refPhone}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- {{userData.travel}} -->
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 small">
                <p>&nbsp;</p>
                <p>Terms and Conditions...</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <button class="btn btn-success" @click="doNext">I Verify the Data Above</button>
            <button class="btn btn-primary" @click="prevStep">Back</button>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->
    </div><!-- end row -->
  </form><!-- end formCreate1 -->
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  components: {
  },
  name: 'visaform4',
  data() {
    return {
      userData: this.$store.state.userData,
      // userData: _.cloneDeep(this.$store.state.userData),
    }
  },
  watch: {

  },
  methods: {
    editPersonal() {
      this.$store.state.visaStep = 'app-visaform1'
    },
    editPassport() {
      this.$store.state.visaStep = 'app-visaform2'
    },
    editTravel() {
      this.$store.state.visaStep = 'app-visaform3'
    },
    doNext() {
      // TODO: do validaiton on checkbox => next
      this.$store.state.visaStep = 'app-visaform5'
    },
    prevStep(e) {
      e.preventDefault();
      this.$store.state.visaStep = 'app-visaform3'
    },
  },
  computed: {
  },
  created: function() {
    window.scrollTo(0,0)
  },
  unmounted() {
  }
}
</script>

<style scoped lang="scss">
  .btn.btn-danger {
    line-height: 1.5;
    svg {
      position: relative;
      top: 4px;
    }
  }
  .tbl-verify {
    tbody {
      tr {
        th {
          width: 30%;
          word-break: break-word;
        }
      }
    }
  }
  .progress .progress-bar {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }
  .uploaded-image {
    width: 13rem;
    height: 16.5rem;
  }
</style>
