<template>
  <address>
    <Form id="formCreate2-2" @submit="onSubmit" @invalid-submit="onInvalidSubmit" :validation-schema="schema" >
      <Field name="address1" :rules="schema.address1" class="form-control" v-slot="{ field, meta }" v-model="childPassport.address1">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="address1">Address</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="address1" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="address2" :rules="schema.address2" class="form-control" v-slot="{ field, meta }" v-model="childPassport.address2">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="address2">Address (continue here if needed)</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="address2" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="city" :rules="schema.city" class="form-control" v-slot="{ field, meta }" v-model="childPassport.city">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="city">Municipality</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="city" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="state" :rules="schema.state" class="form-control" v-slot="{ field, meta }" v-model="childPassport.state">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="state">Province</label>
            <select v-bind="field" class="form-control">
              <option value="">Select a Province</option>
              <option v-for="province in provinces" :key="province.id" :value="province.id">{{province.name}}</option>
            </select>
            <ErrorMessage name="state" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="zipcode" :rules="schema.zipcode" class="form-control" v-slot="{ field, meta }" v-model="childPassport.zipcode">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="zipcode">Postal Code</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="zipcode" class="form-control-feedback small" />
        </div>
      </Field>
      <button class="btn btn-primary" type="submit" id="btnChildSubmit">CHILD SUBMIT</button>
    </Form>
  </address>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import _ from 'lodash'

export default {
  props: [
    'passport',
    'doAdressValidation'
  ],
  components: {
    Field,
    Form,
    ErrorMessage
  },
  name: 'addressca',
  data() {
    return {
      childPassport: {
        address1: this.passport.address1,
        address2: this.passport.address2,
        city: this.passport.city,
        state: this.passport.state,
        zipcode: this.passport.zipcode,
      },
      provinces: [
        {id:"AB", name:"Alberta","country":"CA"},
        {id:"BC", name:"British Columbia","country":"CA"},
        {id:"MB", name:"Manitoba","country":"CA"},
        {id:"NB", name:"New Brunswick","country":"CA"},
        {id:"NL", name:"Newfoundland and Labrador","country":"CA","alt":["Newfoundland","Labrador"]},
        {id:"NS", name:"Nova Scotia","country":"CA"},
        {id:"NU", name:"Nunavut","country":"CA"},
        {id:"NT", name:"Northwest Territories","country":"CA"},
        {id:"ON", name:"Ontario","country":"CA"},
        {id:"PE", name:"Prince Edward Island","country":"CA"},
        {id:"QC", name:"Quebec","country":"CA"},
        {id:"SK", name:"Saskatchewan","country":"CA"},
        {id:"YT", name:"Yukon","country":"CA"}
      ]
    }
  },
  methods: {
    onSubmit(values) {
      console.log('CHILD: form success')
      this.$emit('childValid', true)
      this.$emit('updateAddress', this.childPassport)
    },
    onInvalidSubmit({ values, errors, results }) {
      // console.log(values); // current form values
      // console.log(errors); // a map of field names and their first error message
      // console.log(results); // a detailed map of field names and their validation results
      // console.log('CHILD: invalid form submission. fix errors and retry again.');
      this.$emit('childValid', false)
    },
  },
  watch: {
    'doAdressValidation': function(){
      // console.log('child watch: validate address...')
      document.getElementById("btnChildSubmit").click();
    }
  },
  computed: {
    schema(that=this) {
      return yup.object({
        address1: yup.string().required().label('Address'),
        address2: yup.string().label('Address2'),
        city: yup.string().min(2).label('Municipality'),
        zipcode: yup.string().min(2).label('Postal code'),
        state: yup.string().required().label('Province').test(
          "state_test",
          "Choose a province.",
          function (value) {
            const index = _.findIndex(that.provinces, function(o) { return o.id == value; });
            // console.log(index, value, that.provinces)
            return index > -1;
          }
        ),
      });
    },
  }
}
</script>
