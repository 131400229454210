<template>
  <app-header></app-header>
  <router-view v-slot="{ Component }">

      <component :is="Component" />
  </router-view>


  <app-footer></app-footer>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// var firebaseConfig = {
//   apiKey: "AIzaSyBSBCtJubEZEbVkbQuXJgOmpqihutKSNwo",
//   authDomain: "vue-visa.firebaseapp.com",
//   // The value of `databaseURL` depends on the location of the database
//   databaseURL: "https://vue-visa.firebaseio.com",
//   projectId: "vue-visa",
//   storageBucket: "vue-visa.appspot.com",
//   messagingSenderId: "957797177928",
//   appId: "APP_ID",
//   // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
//   measurementId: "G-MEASUREMENT_ID",
// };

var firebaseConfig = {
  apiKey: "AIzaSyCE54oTjTDMhM0BJT9kwwW-GVLgIHtTqWs",
  authDomain: "vuevisa3.firebaseapp.com",
  projectId: "vuevisa3",
  storageBucket: "vuevisa3.appspot.com",
  messagingSenderId: "232173687364",
  appId: "1:232173687364:web:a583aa13e9404c22416211",
  // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
  // measurementId: "G-MEASUREMENT_ID",
};

export default {
  name: 'App',
  components: {
    // HelloWorld,
    'app-header': Header,
    'app-footer': Footer
  },
  data() {
    return {
      FBA: '',
    }
  },
  methods: {
    mountStripe() {
      console.log('key:',process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      if (window.Stripe === undefined) {
        alert("Stripe V3 library not loaded!");
      } else {
        const wstripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY); //test-mode
        // const stripe = window.Stripe("pk_live_vHLFv4kPpls7bhiIaQBlEwur"); //live-mode
        this.$store.state.stripe = wstripe;
      }

    },
  },
  created() {
    //Init Firebase for the whole app
    this.FBA = initializeApp(firebaseConfig);

    //Store Firebase prefix-calls(shortcuts) to database and storage inside store.js, user mapGetters to retrieve
    this.$store.state.fbDatabase = getFirestore(this.FBA); //data = name of db node
    this.$store.state.fbStorage = getStorage(this.FBA);
  },
  mounted() {
    this.mountStripe();
  },
  unmounted() {
    // this.FBA.delete();
  }
}
</script>

<style lang="scss">
@import "styles/app.scss";
.fa-icon {
  width: auto;
  height: 1em; /* or any other relative font sizes */
}
.hello {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
