<template>
  <div>Error: Route not found!</div>
</template>

<script>
// import {mapGetters} from 'vuex'
export default {
  data(){
    return {
      // headBkg: false,
    }
  },
  computed: {
    // ...mapGetters([
    //   'headerAlt',
    // ]),
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
