<template>
  <header :class="{'header--alt': headerAlt}">
    <nav class="navbar navbar-toggleable-md navbar-light" :class="{'bg-faded':headBkg}" @mouseenter="headerEnter" @mouseleave="headerLeave">
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link to="/" class="navbar-brand">
          <img src="../assets/logo.png" width="30" height="30" class="logo" alt="">
          VisaExpress
      </router-link>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <router-link to="/" class="nav-item" active-class="active">
            <li>
              <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li>
          </router-link>
          <router-link to="/createvisa" class="nav-item">
            <li>
              <a class="nav-link" href="#">Create Visa</a>
            </li>
          </router-link>
          <!-- <li class="nav-item">
            <a class="nav-link disabled" href="#">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#">News</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#">Login</a>
          </li> -->
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return {
      headBkg: false,
    }
  },
  computed: {
    ...mapGetters([
      'headerAlt',
    ]),
  },
  methods: {
    headerEnter: function() {
      this.headBkg = true
    },
    headerLeave: function() {
      this.headBkg = false
    }
  }
}
</script>

<style scoped>

</style>
