<template>
  <Form id="formCreate3" @submit="onSubmit" @invalid-submit="onInvalidSubmit" :validation-schema="schema" >
    <div class="row justify-content-lg-center">
      <div class="col-sm-12 col-lg-10">
        <div class="card card--formbox">
          <div class="card-header">
            <h3>Travel Information</h3>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col-sm-12 col-md-6">

                <Field name="purpose" :rules="schema.purpose" class="form-control" v-slot="{ field, meta }" v-model="travel.purpose">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="purpose">Purpose of travel</label>
                      <select v-bind="field" class="form-control">
                        <option value="">Select a Purpose</option>
                        <option v-for="purpose in tripPurposes" :key="purpose.id" :value="purpose.id" :disabled="!purpose.active">{{purpose.name}}</option>
                      </select>
                      <ErrorMessage name="purpose" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="entryType" :rules="schema.entryType" class="form-control" v-slot="{ field, meta }" v-model="travel.entryType">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="purpose">Entrance Transportation</label>
                      <select v-bind="field" class="form-control">
                        <option value="">Select entry transportation</option>
                        <option v-for="type in entryTypes" :key="type.id" :value="type.id" :disabled="!type.active">{{type.name}}</option>
                      </select>
                      <ErrorMessage name="purpose" class="form-control-feedback small" />
                  </div>
                </Field>

                <transition
                  enter-active-class="animated fadeInUp"
                  leave-active-class="animated fadeOutUp"
                >
                  <component v-bind:is="currentTransport"
                    :travel="travel"
                    :entryTypes="entryTypes"
                    :isTravelValid="isTravelValid"
                    :entryPorts="entryPorts"
                    @childValid="isTravelValid = $event"
                    @updateTransport= "updatePropTransport($event)"
                  ></component>
                </transition>
              </div>
              <transition
                  enter-active-class="animated fadeInUp"
                  leave-active-class="animated fadeOutUp"
                >
                  <component v-bind:is="currentPurpose"
                    :travel="travel"
                    :isPurposeValid="isPurposeValid"
                    @childValid="isPurposeValid = $event"
                    @updatePurpose= "updatePropPurpose($event)"
                  ></component>
              </transition>
            </div>
          </div>
          <div class="card-footer text-right">
            <button class="btn btn-success" type="submit">Next</button>
            <button class="btn btn-primary" @click.stop.prevent="prevStep">Back</button>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->
    </div><!-- end row -->
  </Form><!-- end formCreate1 -->
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import moment from 'moment';

import TravelAIR from '@/components/create/travel/TravelAir'
import TravelLND from '@/components/create/travel/TravelLnd'
import TravelSEA from '@/components/create/travel/TravelSea'
import PurposeVIS from '@/components/create/purpose/PurposeVis'

import {mapGetters} from 'vuex'
import {mapActions} from 'vuex'

export default {
  components: {
    TravelAIR,
    TravelLND,
    TravelSEA,
    PurposeVIS,
    Field,
    Form,
    ErrorMessage
  },
  name: 'visaform3',
  data() {
    return {
      formValid: false,
      hasFriend: false,
      isPurposeValid: false,
      isTravelValid: false,
      travel: {
        purpose: '',
        entryType: '',
        exitType: '',
        refFirst:'',
        refLast:'',
        refPhone:'',
        refAddress:'',
        entryPort:'',
        exitPort:'', //todo: add exit port
        entryDate:'',
        exitDate:'',
        airOrigin:'',
        airFlight:'',
      },
      tripPurposes: [
        { id: 'COM', active: true, name: 'Commuting' },
        { id: 'BUS', active: true, name: 'Business' },
        { id: 'EDU', active: true, name: 'Education' },
        { id: 'SHP', active: true, name: 'Shopping' },
        { id: 'VIS', active: true, name: 'Visiting Friend' },
        { id: 'LEI', active: true, name: 'Leisure, Vacation or Holiday' },
        { id: 'OTH', active: true, name: 'Other' }
      ],
      entryPorts: [
        { id: 'VTE', active: true, name: 'Airport: Wattay International (Vientiane)' },
        { id: 'LPQ', active: true, name: 'Airport: Luang Prabang International' },
        { id: 'KH1', active: false, name: 'KH-LA: Dong Kralor - Veun Kham' },
        { id: 'CH1', active: false, name: 'CH-LA: Mohan - Boten (Yunnan to Luang Namtha province)' },
        { id: 'CH2', active: false, name: 'CH-LA: Xiengkok riverport on Mekong River to Luang Namtha province.', va: false },
        { id: 'CH3', active: false, name: 'CH-LA: Sobhun checkpoint (Yunnan to Phongsaly province)', va: false },
        { id: 'TH1', active: false, name: 'TH-LA: Chiang Khong - Houy Xai (Chiang Rai Province to Bokeo province)' },
        { id: 'TH2', active: false, name: 'TH-LA: Nakaxeng - Kaenthao (Loei province to Sayabouly Province)' },
        { id: 'TH3', active: false, name: 'TH-LA: Nong Khai - Vientiane (Thai-Lao Freindship Bridge No. 1)' },
        { id: 'TH4', active: false, name: 'TH-LA: Bungkan - Pakxan (Nong Khai prov to Bolikhamxai prov.)', va: false },
        { id: 'TH5', active: false, name: 'TH-LA: Nakon Phanom - Thakhaek (Nakon Phanom prov to Khammouane prov.)' },
        { id: 'TH6', active: false, name: 'TH-LA: Mukdaharn - Savannakhet (Mukdaharn prov to Savannakhet prov via Thai-Lao Friendship Bridge No.2)' },
        { id: 'TH7', active: false,  name: 'TH-LA: Chong Mek - Vangtao (Ubon Rathchathani Prov. to Champassak Prov.)' },
        { id: 'VN1', active: false,  name: 'VN-LA: Tay Trang- Sobboun (Laichau, Vietnam to Pongsaly Province)' },
        { id: 'VN2', active: false,  name: 'VN-LA: Nameo - Banteui (Thanh Hua to Huaphanh province)' },
        { id: 'VN3', active: false,  name: 'VN-LA: Namkan - NamCan (Nge An to Xiengkhuang province)' },
        { id: 'VN4', active: false,  name: 'VN-LA: Keoneua - Namphao (Hatinh to Bolikhamxai province)' },
        { id: 'VN5', active: false,  name: 'VN-LA: Chalo - Napao (Guangbinh to Khammoun province)', va: false },
        { id: 'VN6', active: false,  name: 'VN-LA: Lao Bao - Dansavanh (Quang Tri prov to Savannakhet prov)' },
        { id: 'VN7', active: false,  name: 'VN-LA: Bo Y - Phukeua (Kon Tum prov to Attapu prov)' }
      ], active: false, 
      entryTypes: [
        { id: 'AIR', active: true, name: 'Air: Airplane'},
        { id: 'LND', active: false, name: 'Land: Car, Vehicle, Motorcycle, Walking'},
        { id: 'SEA', active: false, name: 'Water: Boat or Ship'},
      ]
    }
  },
  methods: {
    ...mapActions([
      'updateTravel'
    ]),
    prevStep() {
      this.$store.state.visaStep = 'app-visaform2'
    },
    validateTransportation(){
      // console.log('entryType check');

      //do check
      this.isTravelValid = 'trigger'
      var that = this
      setTimeout(function(){
        //wait for transport check, then submit if valid
        if (that.isTravelValid === true) {
          that.submitForm();
        } else {
          // console.log('validateTransportation: child form error');
        }
      },2000)

    },
    submitForm(){
      // console.log('inside submit... submiting to store....')
      //if valid > 1)submit to $store > 2)go to next form
      //1//
      this.updateTravel(this.travel)
      console.log(this.$store.state.userData.travel)
      //2//
      this.$store.state.visaStep = 'app-visaform4' //enable when done
      console.log('app-visaform3 :: ALL FORMS SUCCESSFUL')
    },
    onSubmit(values) {
        //if main form is valid, then check child form before submit
        // console.log('step3 submitted, do check on address')
        // console.log(values);

        //check child Items (a:purpose => b:transportation)
        if (this.hasFriend == true) {
          //do check
          this.isPurposeValid = 'trigger'
          var that = this
          setTimeout(function(){
            // console.log('friend child checked:'+that.isPurposeValid)
            that.validateTransportation()
          },1000)
        } else {
          //todo: clean travel.friend fields
          //move on to next check
          // console.log('is air + !hasFriend : do child > transport check');
          this.validateTransportation()
        }

    },
    onInvalidSubmit({ values, errors, results }) {
      //only checks if main form is invalid...

      // console.log(values); // current form values
      // console.log(errors); // a map of field names and their first error message
      // console.log(results); // a detailed map of field names and their validation results
      // console.log('main: invalid form submission. fix errors and retry again.');
    },
    updatePropTransport(childObj) {
      if (this.isTravelValid == true) {
        this.travel.entryPort = childObj.entryPort;
        this.travel.airOrigin = childObj.airOrigin;
        this.travel.airFlight = childObj.airFlight;
        this.travel.entryDate = childObj.entryDate;
        this.travel.exitDate = childObj.exitDate;
        this.travel.exitType = childObj.exitType;
      } else (
        console.log('child > transport not valid: no transport change')
      )
    },
    updatePropPurpose(childObj) {
      if (this.isPurposeValid == true) {
        this.travel.refFirst = childObj.refFirst;
        this.travel.refLast = childObj.refLast;
        this.travel.refPhone = childObj.refPhone;
        this.travel.refAddress = childObj.refAddress;
      } else (
        console.log('child > transport not valid: no transport change')
      )
    }
  },
  watch: {
    'travel.purpose': function(){
      if (this.travel.purpose === 'VIS') {
        // console.log('watcher: Is visiting friend');
        this.hasFriend = true;
      } else {
        this.hasFriend = false;
      }
    }
  },
  computed: {
    currentPurpose(){
      if (this.travel.purpose == 'VIS') {
        // this.hasFriend = true //todo-v3: vue/no-side-effects-in-computed-properties
        return 'PurposeVIS'
      } else {
        // this.hasFriend = false //todo-v3: vue/no-side-effects-in-computed-properties
        return false
      }
    },
    currentTransport(){
      if (this.travel.entryType != '') {
        return 'Travel'+this.travel.entryType
      } else {
        return false
      }
    },
    schema() {
      return yup.object({
        EndDate: yup.date(),
        purpose: yup.string().required().label('Travel purpose'),
        entryType: yup.string().required().label('Entry type'),

      });
    },
  },
  created() {
    //check store to see if we are in edit mode
      const data = this.$store.state.userData.travel;
      if (Object.keys(data).length === 0) {
        console.log('store.travel is empty')
      } else {
        // console.log(data)
        console.log('load data')
        this.travel = data
      }

    //if edit mode, move state.userData into forms

    //else > clear forms
    window.scrollTo(0,0)
  }
}
</script>

<style scoped>

</style>
