<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <Form>
      <div class="row justify-content-lg-center">
        <div class="col-sm-12 col-lg-10">
          <div class="card card--formbox">
            <div class="card-header">
              <h3>Test Form Information</h3>
            </div>
            <div class="card-block">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                    <Field name="firstName" rules="required|minLength:8" class="form-control" v-slot="{ field, meta }">
                      <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                          <label for="firstName">First Name</label>
                          <input v-bind="field" class="form-control" />
                          <!-- <pre>{{ meta }}</pre> -->
                          <ErrorMessage name="firstName" class="form-control-feedback small" />
                      </div>
                    </Field>
                    <Field name="email" value="" type="email" rules="required|email" v-slot="{ field, meta }">
                      <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                        <label for="email">eMail</label>
                        <input v-bind="field" class="form-control" />
                        <pre>{{ meta }}</pre>
                        <ErrorMessage name="email" />
                      </div>
                    </Field>
                    

                </div>
                <div class="col-sm-12 col-md-6">
                  <label>Upload Photo: </label>
                  <!-- <div v-show="!showCropped"> -->

                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <!-- <button class="btn btn-primary" v-model="goBack" disabled>Back</button> -->
              <button class="btn btn-primary" type="submit">Next</button>
            </div>
          </div><!-- end card -->
        </div><!-- end col -->
      </div><!-- end row -->
    </Form>

  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';

export default {
  components: {
    // VField: Field,
    Field,
    Form,
    ErrorMessage
  },
  name: 'HelloWorld',
  props: {
    // msg: String
  },
  data() {
    return {
      msg: 'Hello World'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
