<template>
  <div>
    <section class="hero-work" style="background:#333;">
        <div id="loopVideo" class="loop-video" style="background: url('http://ninjacpu.com/vid/vid-loop.jpg') center; display: none;"></div>
        <!-- overlay video when clicked -->

        <div class="container hw-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="table">
                        <div class="cell">
                            <h2>Traveling to Laos?</h2>
                            <div class="subtitle">
                                Online Lao Travel E-Visas only $175
                            </div>
                            <router-link to="/createvisa" class="cta">
                                <div>
                                  <button class="btn-play" id="btnPlayReel" style="width: 22rem;">Start E-Visa Now</button>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hw-next" v-show="true">
            <i class="fa-solid fa-chevron-down animated infinite bounce"></i>
        </div>
    </section>

    <section class="container-fluid" id="splashGrid">
        <div class="row">
          <div class="col-sm-6 col-md-3 no-gutters">
            <div class="sg-box sg-box--01">
              <div class="bkg"></div>
              <div class="sfb-title sfb-title--inverse">Compatible<br><span>On all devices</span></div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 no-gutters">
            <div class="sg-box sg-box--02">
              <div class="bkg"></div>
              <div class="sfb-title sfb-title--inverse">Online Approval<br><span>In 3 business days</span></div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 no-gutters">
            <div class="sg-box sg-box--03">
              <div class="bkg"></div>
              <div class="sfb-title sfb-title--inverse">Online Payment<br><span>All major credit cards accepted</span></div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 no-gutters">
            <div class="sg-box sg-box--04">
              <div class="bkg"></div>
              <div class="sfb-title sfb-title--inverse">Faster Processing<br><span>Express lanes at most borders</span></div>
            </div>
          </div>
        </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import $ from 'jquery'
import vide from 'vide'
export default {
  name: 'home',
  data(){
    return {
      video_id: '150928998' //vimeo video id
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'headerAbsolute',
    ]),
  },
  mounted: function() {
    //set header
    this.$store.state.headerAlt = true

    $(document).ready(() => {
      console.log('hello, i\'ve been loaded')

        $('#loopVideo').vide('http://ninjacpu.com/vid/vid-loop', {
          posterType: 'jpg',
          playbackRate: 1,
          muted: true,
          loop: true,
          autoplay: true,
          position: '50% 50%',
          resizing: true
        });
        $('#loopVideo').fadeIn('slow');
    })
  },
  unmounted: function() {
    console.log('home destroyed')
    // this.$store.state.headerAlt = false
  }
}
</script>

<style lang="scss">

</style>
