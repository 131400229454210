<template>
  <Form id="formCreate2" @submit="onSubmit" @invalid-submit="onInvalidSubmit" :validation-schema="schema" >
    <div class="row justify-content-lg-center">
      <div class="col-sm-12 col-lg-10">
        <div class="card card--formbox">
          <div class="card-header">
            <h3>Passport Information</h3>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <Field name="passportCountry" :rules="schema.passportCountry" class="form-control" v-slot="{ field, meta }" v-model="passport.passportCountry">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="passportCountry">Passport Country</label>
                      <select v-bind="field" class="form-control">
                        <option value="">Select a Country</option>
                        <option value="US">US: United States</option>
                        <option value="CA">CA: Canada</option>
                        <option v-for="country in countries" :key="country.code" :value="country.code" disabled>{{country.code}}: {{country.name}}</option>
                      </select>
                      <ErrorMessage name="passportCountry" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="passportNumber" :rules="schema.passportNumber" class="form-control" v-slot="{ field, meta }" v-model="passport.passportNumber">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="passportNumber">Passport Number</label>
                      <input v-bind="field" class="form-control" />
                      <ErrorMessage name="passportNumber" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="passportIssuePlace" :rules="schema.passportIssuePlace" class="form-control" v-slot="{ field, meta }" v-model="passport.passportIssuePlace">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="passportIssuePlace">Passport Issue Place</label>
                      <input v-bind="field" class="form-control" />
                      <ErrorMessage name="passportIssuePlace" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="passportIssueDate" :rules="schema.passportIssueDate" class="form-control" v-slot="{ field, meta }" v-model="passport.passportIssueDate">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="passportIssueDate">Passport Issue Date (mm/dd/yyyy)</label>
                      <input v-bind="field" class="form-control" type="date" :max="dateHelper.today" />
                      <ErrorMessage name="passportIssueDate" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="passportExpiration" :rules="schema.passportExpiration" class="form-control" v-slot="{ field, meta }" v-model="passport.passportExpiration">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="passportExpiration">Passport Expiration Date (mm/dd/yyyy)</label>
                      <input v-bind="field" class="form-control" type="date" :min="dateHelper.today" />
                      <ErrorMessage name="passportExpiration" class="form-control-feedback small" />
                  </div>
                </Field>

              </div>
              <div class="col-sm-12 col-md-6">
                <!-- template: {{changeCountry}} -->
                <transition
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutLeft"
                >
                  <component :is="changeCountry"
                    :passport="passport"
                    :doAdressValidation="doAdressValidation"
                    @childValid="addressValid = $event"
                    @updateAddress= "updatePropAddress($event)"
                  ></component>
                </transition>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-success" type="submit">Next</button>
            <button class="btn btn-primary" @click.stop.prevent="prevStep">Back</button>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->
    </div><!-- end row -->
  </Form><!-- end formCreate1 -->
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

import AddressUS from '@/components/create/address/AddressUS'
import AddressCA from '@/components/create/address/AddressCA'

import {mapGetters} from 'vuex'
import {mapActions} from 'vuex'
import moment from 'moment'

export default {
  components: {
    AddressUS,
    AddressCA,
    Field,
    Form,
    ErrorMessage
  },
  name: 'visaform2',
  data() {
    return {
      addressValid: false, //use child emit to change this field, check for true: on parent form submit
      doAdressValidation: false,
      // countries: []
      dateHelper: {
        today: moment(Date.now()).format('YYYY-MM-DD'),
      },
      passport: {
        passportCountry: '',
        passportNumber: '',
        passportIssuePlace: '',
        passportIssueDate: '',
        passportExpiration: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      },
    }
  },
  methods: {
    ...mapActions([
      'updatePassport',
      'updateVisaStep'
    ]),
    prevStep(e) {
      e.preventDefault();
      this.$store.state.visaStep = 'app-visaform1'
    },
    onSubmit(values) {
        //if main form is valid, then check child form before submit
        // console.log('step2 submitted, do check on address')

        //check child
        this.doAdressValidation = !this.doAdressValidation

        //wait for child validation response
        var that = this;
        setTimeout(function(){
          console.log('child checked:'+that.addressValid)
          //check form
          if (that.addressValid == true) {
              that.submitForm()
          } else {
            console.log('there were errors found on child form')
          }
        },1000)
    },
    onInvalidSubmit({ values, errors, results }) {
      //only checks if main form is invalid...
      // console.log(values); // current form values
      // console.log(errors); // a map of field names and their first error message
      // console.log(results); // a detailed map of field names and their validation results
      // console.log('invalid form submission. fix errors and retry again.');
    },
    submitForm() {
      //if valid > 1)submit to $store > 2)go to next form
      //1//
      this.updatePassport(this.passport)
      console.log(this.$store.state.userData.passport)
      //2//
      this.$store.state.visaStep = 'app-visaform3'
      console.log('app-visaform2 :: ALL FORMS SUCCESSFUL')
    },
    updatePropAddress(childObj) {
      if (this.addressValid == true) {
        this.passport.address1 = childObj.address1;
        this.passport.address2 = childObj.address2;
        this.passport.city = childObj.city;
        this.passport.state = childObj.state;
        this.passport.zipcode = childObj.zipcode;
      } else (
        console.log('child > address not valid: no address change')
      )
    }
  },
  computed: {
    ...mapGetters([
      'countries'
    ]),
    changeCountry() {
      var c;
      if (this.passport.passportCountry != '') {
        c = 'Address'+this.passport.passportCountry
        return c
      } else {
        c = ''
        return c
      }
    },
    schema() {
      return yup.object({
        EndDate: yup.date(),
        passportCountry: yup.string().required().label('Passport Country'),
        passportNumber: yup.string().min(10).label('Passport number'),
        passportIssuePlace: yup.string().min(2).label('Passport issue place'),
        passportIssueDate: yup.date().typeError('Invalid date').test(
          "dob_test",
          "Pass. issue date must be in the past.",
          function (value) {
            return moment(value) <  moment();
          }
        ).label('Pass. issue date'),
        passportExpiration: yup.date().typeError('Invalid date').test(
          "dob_test",
          "Pass. expiration date must be in the future.",
          function (value) {
            return moment(value) >  moment();
          }
        ).label('Pass. expiration date'),
                
      });
    },
  },
  created() {
    //check store to see if we are in edit mode
      const data = this.$store.state.userData.passport;
      if (Object.keys(data).length === 0) {
        console.log('store.passport is empty')
      } else {
        // console.log(data)
        console.log('load data')
        this.passport = data
      }

    //if edit mode, move state.userData into forms

    //else > clear forms
    window.scrollTo(0,0)
  }
}
</script>

<style scoped>

</style>
