<template>
  <section>
    <div class="form-group" :class="{'has-success': fields.dirty('entryPort'), 'has-danger': errors.has('entryPort') }">
      <label for="entryPort">Entrance Port (Sea)</label>
      <select name="entryPort" class="form-control"
        v-model="childTravel.entryPort"
        data-vv-delay="500"
        data-vv-as="entrance port"
        v-validate.initial="{required:true}"
        :class="{'form-control-success': fields.dirty('entryPort'), 'form-control-danger': errors.has('entryPort') }"
      >
        <option value="">Select an Entry Port</option>
        <option v-for="port in entryPorts" :key="port.id" :value="port.id">{{port.name}}</option>
      </select>
      <span class="form-control-feedback small" v-show="errors.has('entryPort')">{{ errors.first('entryPort') }}</span>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group" :class="{'has-success': fields.dirty('entryDate'), 'has-danger': errors.has('entryDate') }">
          <input type="text" name="approvalDate" class="hidden-xs-up"
            v-model="dateHelper.approvalDate"
            data-vv-as="e-visa approval date (3 days from today)"
            v-validate.initial="{required:true}"
          >
          <label for="entryDate">Entry Date</label>
          <input type="text" name="entryDate" class="form-control"
            v-model="childTravel.entryDate"
            data-vv-delay="500"
            data-vv-as="entry date"
            v-validate.initial="{required:true,date_format:'MM/DD/YYYY',after:'approvalDate'}"
            :class="{'form-control-success': fields.dirty('entryDate'), 'form-control-danger': errors.has('entryDate') }"
          >
          <span class="form-control-feedback small" v-show="errors.has('entryDate')">{{ errors.first('entryDate') }}</span>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group" :class="{'has-success': fields.dirty('exitDate'), 'has-danger': errors.has('exitDate') }">
          <label for="exitDate">Exit Date</label>
          <input type="text" name="exitDate" class="form-control"
            v-model="childTravel.exitDate"
            data-vv-delay="500"
            data-vv-as="exit date"
            v-validate.initial="{required:true,date_format:'MM/DD/YYYY',after:'entryDate'}"
            :class="{'form-control-success': fields.dirty('exitDate'), 'form-control-danger': errors.has('exitDate') }"
          >
          <span class="form-control-feedback small" v-show="errors.has('exitDate')">{{ errors.first('exitDate') }}</span>
        </div>
      </div>
    </div>
    <div class="form-group" :class="{'has-success': fields.dirty('exitType'), 'has-danger': errors.has('exitType') }">
      <label for="exitType">Exit Transportation</label>
      <select name="exitType" class="form-control"
        v-model="childTravel.exitType"
        data-vv-delay="500"
        data-vv-as="entrance port"
        v-validate.initial="{required:true}"
        :class="{'form-control-success': fields.dirty('exitType'), 'form-control-danger': errors.has('exitType') }"
      >
        <option value="">Select exit type</option>
        <option v-for="etype in entryTypes" :key="etype.id" :value="etype.id">{{etype.name}}</option>
      </select>
      <span class="form-control-feedback small" v-show="errors.has('exitType')">{{ errors.first('exitType') }}</span>
    </div>
    <br>
  </section>
</template>

<script>
import moment from 'moment'
export default {
  props: [
    'travel',
    'entryTypes',
    'isTravelValid',
    'entryPorts',
  ],
  name: 'travelsea',
  data() {
    return {
      childTravel: this.travel,
      dateHelper: {
        approvalDate: moment(Date.now()).add(3,'days').format('MM/DD/YYYY'),
      },
    }
  },
  watch: {
    'isTravelValid': function(){
      // console.log('child watch: validate reference...')
      this.$validator.validateAll();
      if (!this.errors.any()) {
        // console.log('address complete')
        this.$emit('childValid', true)
      } else {
        // console.log('address form has errors')
        this.$emit('childValid', false)
      }
    }
  }
}
</script>
