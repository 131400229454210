// import Vue from 'vue'
// import { createApp } from 'vue'
import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import 'vue-advanced-cropper/dist/style.css';
//Global
// import moment from 'moment'
// Vue.use(moment)

// import VeeValidate from 'vee-validate'
// Vue.use(VeeValidate)

import { defineRule } from 'vee-validate';
defineRule('required', value => {
  if (!value || !value.length) {
    return 'This field is required';
  }
  return true;
});
defineRule('email', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if email
  if (!value.toLowerCase().match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )){
  // if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
    return 'This field must be a valid email';
  }
  return true;
});
defineRule('minLength', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length < limit) {
    return `This field must be at least ${limit} characters`;
  }
  return true;
});

createApp(App).use(router).use(store).mount('#app')
