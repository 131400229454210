<template>
  <!-- <Form id="formCreate1" @submit.prevent="validateBeforeSubmit"> -->
  <Form id="formCreate1" @submit="onSubmit" @invalid-submit="onInvalidSubmit" :validation-schema="schema" >
    <div class="row justify-content-lg-center">
      <div class="col-sm-12 col-lg-10">
        <div class="card card--formbox">
          <div class="card-header">
            <h3>Personal Information</h3>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col-sm-12 col-md-6">

                <Field name="firstName" :rules="schema.firstName" class="form-control" v-slot="{ field, meta }" v-model="personal.firstName">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="firstName">First Name</label>
                      <input v-bind="field" class="form-control" />
                      <ErrorMessage name="firstName" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="lastName" :rules="schema.lastName" class="form-control" v-slot="{ field, meta }" v-model="personal.lastName">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="lastName">Last Name</label>
                      <input v-bind="field" class="form-control" />
                      <ErrorMessage name="lastName" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="dob" :rules="schema.dob" class="form-control" v-slot="{ field, meta }" v-model="personal.dob">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="dob">Date of Birth (mm/dd/yyyy)</label>
                      <input v-bind="field" class="form-control" type="date" :max="dateHelper.today" />
                      <ErrorMessage name="dob" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="birthPlace" :rules="schema.birthPlace" class="form-control" v-slot="{ field, meta }" v-model="personal.birthPlace">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="birthPlace">Birth Place</label>
                      <input v-bind="field" class="form-control" />
                      <ErrorMessage name="birthPlace" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="nationality" :rules="schema.nationality" class="form-control" v-slot="{ field, meta }" v-model="personal.nationality">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="nationality">Nationality</label>
                      <input v-bind="field" class="form-control" />
                      <ErrorMessage name="nationality" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="occupation" :rules="schema.occupation" class="form-control" v-slot="{ field, meta }" v-model="personal.occupation">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="occupation">Occupation</label>
                      <input v-bind="field" class="form-control" />
                      <ErrorMessage name="occupation" class="form-control-feedback small" />
                  </div>
                </Field>
                <Field name="email" :rules="schema.email" class="form-control" v-slot="{ field, meta }" v-model="personal.email">
                  <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                      <label for="email">Email</label>
                      <input v-bind="field" class="form-control" />
                      <ErrorMessage name="email" class="form-control-feedback small" />
                  </div>
                </Field>
              </div>
              <div class="col-sm-12 col-md-6">
                
                <!-- <div v-show="!showCropped"> -->
                <ImageCropper
                  :photos = "personal.photos"
                  :doPhotosValidation = "doPhotosValidation"
                  @childValid="photosValid = $event"
                  @updatePhotos= "updatePropPhotos($event)"
                />
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <!-- <button class="btn btn-primary" v-model="goBack" disabled>Back</button> -->
            <button class="btn btn-success" type="submit">Next</button>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->
    </div><!-- end row -->
  </Form><!-- end formCreate1 -->
</template>

<script>


import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

import $ from 'jquery'
// import slim from 'slim'
// import slim from '../../../public/lib/slim.jquery.min.js'
import moment from 'moment'

import ImageCropper from '@/components/create/ImageCropper.vue';


import {mapActions} from 'vuex'
import { boolean } from 'yup/lib/locale';
export default {
  components: {
  //   moment
    Field,
    Form,
    ErrorMessage,
    ImageCropper
  },
  name: 'visaform1',
  data() {
    return {
      photosValid: false, //use child emit to change this field, check for true: on parent form submit
      doPhotosValidation: false,
      dateHelper: {
        today: moment(Date.now()).format('YYYY-MM-DD'),
      },
      personal: {
        firstName: '',
        lastName: '',
        dob: '',
        birthPlace: '',
        nationality: '',
        occupation: '',
        email: '',
        photos: {
          input:'',
          coordinates: '',
          output:'',
          inputType: '',
        }
      },
      tempPhotos: {}
      // personal: this.$store.state.userData.personal
    }
  },
  methods: {
    ...mapActions([
      'updatePersonal',
    ]),
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;

        //turn file object into blob(base64)
        var reader = new FileReader();
        // var vm = this;
        reader.onload = (e) => {
          // vm.image = e.target.result;
          // console.log(reader.result)

          // this.personal.photos.input = files[0]
          this.personal.photos.input = reader.result
          // console.log(files[0].name)
          // console.log(this.personal.photos.input)

        };
        var blob = reader.readAsDataURL(files[0])

    },
    reloadCropped() {
      this.showCropped = true
    },
    /// vee-validate v4
    onSubmit(values) {
      this.doPhotosValidation = !this.doPhotosValidation;
      if(!this.photosValid) {
        alert('Photo missing. Please add a photo.');
        return;
      }

      //needs to wait for photo prop update. then do final store submission

      setTimeout(()=> {
        // console.log('form valid: do submit')
        //if valid > 1)submit to $store > 2)go to next form
        //1//
        // this.$store.state.userData.personal = this.personal
        let perObj = {
          ...this.personal,
          photos: this.tempPhotos
        }
        this.updatePersonal(perObj)
        console.log(this.$store.state.userData.personal)
        //2//
        this.$store.state.visaStep = 'app-visaform2'
        console.log('app-visaform1 :: ALL FORMS SUCCESSFUL')

      }, 1000)
    },
    onInvalidSubmit({ values, errors, results }) {
      // console.log(values); // current form values
      // console.log(errors); // a map of field names and their first error message
      // console.log(results); // a detailed map of field names and their validation results
      // console.log('invalid form submission. fix errors and retry again.');
    },
    /// Requires: child component -> ImageCropper
    updatePropPhotos(childObj) {
      console.log('updatePropPhotos: updating personal.photos prop')
      if (this.photosValid == true) {
        this.tempPhotos = childObj;
      } else (
        console.log('child > photos not valid: no change')
      )
    }
  },
  // filters: {
  //   moment: function (date) {
  //     return moment(date).format('MM/DD/YYYY');
  //   }
  // },
  computed: {
    // ...mapGetters({
    //   uuid,
    // }),
    // dateHelper,
    schema() {
      return yup.object({
        EndDate: yup.date(),
        firstName: yup.string().min(2).label('First name'),
        lastName: yup.string().min(2).label('Last name'),
        birthPlace: yup.string().min(2).label('Birth place'),
        nationality: yup.string().min(2).label('Nationality'),
        occupation: yup.string().min(2).label('Occupation'),
        email: yup.string().required().email().label('Email'),
        dob: yup.date().typeError('Invalid date').test(
          "dob_test",
          "D.O.B. must be in the past.",
          function (value) {
            return moment(value) <  moment();
          }
        ).label('D.O.B.'),
                
      });
    },
  },
  created() {

    const data = this.$store.state.userData.personal;
    if (Object.keys(data).length === 0) {
      console.log('store.personal is empty')
    } else {
      // console.log(data)
      console.log('load data')
      this.personal = data;
    }

    ///Load Cropper
    var that = this
    window.scrollTo(0,0)
  },
  unmounted() {

	}
}


</script>

<style scoped>

</style>
