<template>
  <section class="container main">
    <div class="row">
      <div class="col-12">
        <h4>Test Vue-Stripe. Successful Payment</h4>
        <p>Your information and payment was processed! Please wait patiently for further instruction. Feel free to visit our best of Laos travel locations blog for ideas on your next trip.</p>
        <router-link to="/"><button class="btn btn-primary">Travel Ideas</button></router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <p>Actions:</p>
        <button class="btn btn-primary" @click="doSaveFile('input')">
          Save File 1
        </button>
        <br />
        <br />
        <button class="btn btn-primary" @click="doSaveFile('output')">
          Save File 2
        </button>
        <br />
        <br />
        <button class="btn btn-primary" @click="doSaveData">
          Save Data to DB
        </button>
        <br />
        <br />
        <button class="btn btn-primary" @click="chainSave">
          Chain Save: File + Data
        </button>
      </div>
      <div class="col-6">
        <div class="status">
          <p>ID : {{ userData.uuid }}</p>
          <p>
            Full Name : {{ userData.personal.firstName }}
            {{ userData.personal.lastName }}
          </p>
          <p>Photo(Input) Uploaded? : {{ uploadedPhoto1 }}</p>
          <p>Photo(Output) Uploaded? : {{ uploadedPhoto2 }}</p>
          <p>Data Saved to FB? : {{ uploadedData }}</p>
          <p>
            TODO: add a thank you message, and tell the user what to do next
          </p>
          stripe: {{ tempUser.stripe }}
          <br />
          photos: {{ tempUser.personal.photos }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions} from 'vuex'
import _ from "lodash";
import { collection, addDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default {
  name: "stripeSuccess",
  components: {},
  data() {
    return {
      handler: "",
      uploadPercent: 0,
      uploadedPhoto1: false,
      uploadedPhoto2: false,
      uploadedData: false,
      /// MAIN
      fbStorage: this.$store.state.fbStorage,
      fbDatabase: this.$store.state.fbDatabase,
      userData: this.$store.state.userData, //?not working using mapGetter
      tempUser: _.cloneDeep(this.$store.state.userData),
    };
  },
  methods: {
    ...mapActions([
      'clearUserData'
    ]),
    async doSaveFile(mode) {
      let filename = ''
      let filedata = '';
      if (mode === 'input') {
        filedata = this.userData.personal.photos.input;
        filename = this.userData.uuid + "_input.jpg";
      }
      else if (mode === 'output') {
        filedata = this.userData.personal.photos.output;
        filename = this.userData.uuid + ".jpg";
      }

      const storage = this.fbStorage;
      const storageRef = ref(storage, 'user_photos/'+filename);

      let docRef2;
      try {
        docRef2 = await uploadString(storageRef, filedata, "data_url");
        console.log("File saved with ID: ", docRef2.metadata.name);
        if (mode === 'input') {
          this.uploadedPhoto1 = true;
          this.tempUser.personal.photos.input = filename;
        }
        else if (mode === 'output') {
          this.uploadedPhoto2 = true;
          this.tempUser.personal.photos.output = filename;
        }
      } catch (e) {
        console.error("Error saving document: ", e);
      }

      return docRef2;
    },
    async doSaveData() {
      const db = this.fbDatabase;
      let docRef;

      //TODO: create tempData variable, and mutate the photos obj use links instead of base64 string.

      try {
        // const docRef = await addDoc(collection(db, "users"), {
        //   first: "Ada",
        //   last: "Lovelace",
        //   born: 1815
        // });
        docRef = await addDoc(collection(db, "users"), this.tempUser);
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      // return docRef;
      return true;
    },
    async chainSave() {
      //showloading(true);
      let task1 = await this.doSaveFile("input");
      let task2 = await this.doSaveFile("output");
      this.uploadedData = await this.doSaveData();
      let task4 = await this.clearUserData();
      //showloading(false);
    }
  },
  created: function () {
    //WHEN ALL IS DONE
    // this.chainSave();
  },
};
</script>

<style scoped lang="scss">
.main {
  display: block;
  min-height: 20rem;
  > .row {
    margin: 4rem 0;
  }
}
</style>