<template>
  <div class="col-sm-12 col-md-6">
    <Form id="formCreate3-2-1" @submit="onSubmit" @invalid-submit="onInvalidSubmit" :validation-schema="schema" >
      <Field name="refFirst" :rules="schema.refFirst" class="form-control" v-slot="{ field, meta }" v-model="childTravel.refFirst">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="refFirst">Friend: First Name</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="refFirst" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="refLast" :rules="schema.refLast" class="form-control" v-slot="{ field, meta }" v-model="childTravel.refLast">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="refLast">Friend: Last Name</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="refLast" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="refPhone" :rules="schema.refPhone" class="form-control" v-slot="{ field, meta }" v-model="childTravel.refPhone">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="refPhone">Friend: Phone</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="refPhone" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="refAddress" :rules="schema.refAddress" class="form-control" v-slot="{ field, meta }" v-model="childTravel.refAddress">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="refAddress">Friend: Address</label>
            <textarea v-bind="field" class="form-control" />
            <ErrorMessage name="refAddress" class="form-control-feedback small" />
        </div>
      </Field>
      
      <button class="btn btn-primary invisible" type="submit" id="btnChildSubmit2">CHILD SUBMIT</button>
    </Form>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    Field,
    Form,
    ErrorMessage
  },
  props: [
    'travel',
    'isPurposeValid'
  ],
  name: 'purposevis',
  data() {
    return {
      childTravel: {
        refFirst: this.travel.refFirst,
        refLast: this.travel.refLast,
        refPhone: this.travel.refPhone,
        refAddress: this.travel.refAddress,
      }
    }
  },
  methods: {
    onSubmit(values) {
      console.log('CHILD-Pur: form success')
      this.$emit('childValid', true)
      this.$emit('updatePurpose', this.childTravel)
    },
    onInvalidSubmit({ values, errors, results }) {
      // console.log(values); // current form values
      // console.log(errors); // a map of field names and their first error message
      // console.log(results); // a detailed map of field names and their validation results
      // console.log('CHILD-Pur: invalid form submission. fix errors and retry again.');
      this.$emit('childValid', false)
    },
    triggerSubmit() {
      console.log('childPurpose: mouseleave > submit')
      document.getElementById("btnChildSubmit2").click()
    }
  },
  watch: {
    'isPurposeValid': function(){
      // console.log('child purpose watch: validate reference...')
      document.getElementById("btnChildSubmit2").click(); //triggers child -> submit
    }
  },
  computed: {
    schema(that=this) {
      return yup.object({
        refFirst: yup.string().min(2).label('Friend first name'),
        refLast: yup.string().min(2).label('Friend last name'),
        refPhone: yup.string().min(5).label('Friend phone number'),
        refAddress: yup.string().min(5).label('Friend full address'),
      });
    },
  }
}
</script>
