<template>
  <address>
    <Form id="formCreate2-1" @submit="onSubmit" @invalid-submit="onInvalidSubmit" :validation-schema="schema" >
      <Field name="address1" :rules="schema.address1" class="form-control" v-slot="{ field, meta }" v-model="childPassport.address1">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="address1">Address</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="address1" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="address2" :rules="schema.address2" class="form-control" v-slot="{ field, meta }" v-model="childPassport.address2">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="address2">Address (continue here if needed)</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="address2" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="city" :rules="schema.city" class="form-control" v-slot="{ field, meta }" v-model="childPassport.city">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="city">City</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="city" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="state" :rules="schema.state" class="form-control" v-slot="{ field, meta }" v-model="childPassport.state">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="state">State</label>
            <select v-bind="field" class="form-control">
              <option value="">Select a State</option>
              <option v-for="state in states" :key="state.id" :value="state.id">{{state.name}}</option>
            </select>
            <ErrorMessage name="state" class="form-control-feedback small" />
        </div>
      </Field>
      <Field name="zipcode" :rules="schema.zipcode" class="form-control" v-slot="{ field, meta }" v-model="childPassport.zipcode">
        <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
            <label for="zipcode">Zipcode</label>
            <input v-bind="field" class="form-control" />
            <ErrorMessage name="zipcode" class="form-control-feedback small" />
        </div>
      </Field>
      <button class="btn btn-primary invisible" type="submit" id="btnChildSubmit">CHILD SUBMIT</button>
    </Form>
  </address>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import _ from 'lodash'

export default {
  props: [
    'passport',
    'doAdressValidation'
  ],
  components: {
    Field,
    Form,
    ErrorMessage
  },
  name: 'addressus',
  data() {
    return {
      childPassport: {
        address1: this.passport.address1,
        address2: this.passport.address2,
        city: this.passport.city,
        state: this.passport.state,
        zipcode: this.passport.zipcode,
      },
      states: [
        {id:'AL', name:'Alabama'},
        {id:'AK', name:'Alaska'},
        {id:'AZ', name:'Arizona'},
        {id:'AR', name:'Arkansas'},
        {id:'CA', name:'California'},
        {id:'CO', name:'Colorado'},
        {id:'CT', name:'Connecticut'},
        {id:'DE', name:'Delaware'},
        {id:'DC', name:'District Of Columbia'},
        {id:'FL', name:'Florida'},
        {id:'GA', name:'Georgia'},
        {id:'HI', name:'Hawaii'},
        {id:'ID', name:'Idaho'},
        {id:'IL', name:'Illinois'},
        {id:'IN', name:'Indiana'},
        {id:'IA', name:'Iowa'},
        {id:'KS', name:'Kansas'},
        {id:'KY', name:'Kentucky'},
        {id:'LA', name:'Louisiana'},
        {id:'ME', name:'Maine'},
        {id:'MD', name:'Maryland'},
        {id:'MA', name:'Massachusetts'},
        {id:'MI', name:'Michigan'},
        {id:'MN', name:'Minnesota'},
        {id:'MS', name:'Mississippi'},
        {id:'MO', name:'Missouri'},
        {id:'MT', name:'Montana'},
        {id:'NE', name:'Nebraska'},
        {id:'NV', name:'Nevada'},
        {id:'NH', name:'New Hampshire'},
        {id:'NJ', name:'New Jersey'},
        {id:'NM', name:'New Mexico'},
        {id:'NY', name:'New York'},
        {id:'NC', name:'North Carolina'},
        {id:'ND', name:'North Dakota'},
        {id:'OH', name:'Ohio'},
        {id:'OK', name:'Oklahoma'},
        {id:'OR', name:'Oregon'},
        {id:'PA', name:'Pennsylvania'},
        {id:'RI', name:'Rhode Island'},
        {id:'SC', name:'South Carolina'},
        {id:'SD', name:'South Dakota'},
        {id:'TN', name:'Tennessee'},
        {id:'TX', name:'Texas'},
        {id:'UT', name:'Utah'},
        {id:'VT', name:'Vermont'},
        {id:'VA', name:'Virginia'},
        {id:'WA', name:'Washington'},
        {id:'WV', name:'West Virginia'},
        {id:'WI', name:'Wisconsin'},
        {id:'WY', name:'Wyoming'}
      ]
    }
  },
  methods: {
    onSubmit(values) {
      console.log('CHILD: form success')
      this.$emit('childValid', true)
      this.$emit('updateAddress', this.childPassport)
    },
    onInvalidSubmit({ values, errors, results }) {
      // console.log(values); // current form values
      // console.log(errors); // a map of field names and their first error message
      // console.log(results); // a detailed map of field names and their validation results
      // console.log('CHILD: invalid form submission. fix errors and retry again.');
      this.$emit('childValid', false)
    },
  },
  watch: {
    'doAdressValidation': function(){
      // console.log('child watch: validate address...')
      document.getElementById("btnChildSubmit").click();
    }
  },
  computed: {
    schema(that=this) {
      return yup.object({
        address1: yup.string().required().label('Address'),
        address2: yup.string().label('Address2'),
        city: yup.string().min(2).label('City'),
        zipcode: yup.string().min(5).label('Zipcode'),
        state: yup.string().required().label('State').test(
          "state_test",
          "Choose a state.",
          function (value) {
            const index = _.findIndex(that.states, function(o) { return o.id == value; });
            // console.log(index, value, that.states)
            return index > -1;
          }
        ),
      });
    },
  }
}
</script>
