<template>
  <div>
    <form id="payment-form" @submit.prevent="stripeSubmit">
      <div class="row justify-content-lg-center">
        <div class="col-sm-12 col-lg-10">
          <div class="card card--formbox">
            <div class="card-header">
              <h3>Complete your payment</h3>
            </div>
            <div class="card-block">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <!-- <Field name="firstName" :rules="schema.firstName" class="form-control" v-slot="{ field, meta }" v-model="personal.firstName">
                    <div class="form-group" :class="{'has-success': meta.valid, 'has-danger': meta.touched && !meta.valid }">
                        <label for="firstName">First Name</label>
                        <input v-bind="field" class="form-control" />
                        <ErrorMessage name="firstName" class="form-control-feedback small" />
                    </div>
                  </Field> -->
                  <div class="form-group">
                    <label for="paymentName">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="paymentName"
                      aria-describedby="nameHelp"
                      required
                    />
                    <small id="nameHelp" class="form-text text-muted"
                      >Full name, first and last.</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="paymentEmail">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="paymentEmail"
                      aria-describedby="emailHelp"
                      required
                    />
                    <small id="emailHelp" class="form-text text-muted"
                      >We'll never share your email with anyone else.</small
                    >
                  </div>
                  <div class="form-group">
                    <label for="paymentCard">Payment Card</label>
                    <div class="card card-bkg">
                      <div id="card-element">
                        <!-- Elements will create input elements here -->
                      </div>
                    </div>
                    <small id="emailHelp" class="form-text text-muted">
                      <div id="card-errors" role="alert"></div>
                    </small>
                  </div>

                  <button type="submit" class="btn btn-dark mt-1 mb-4">
                    Pay
                  </button>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="card p-4 card-msgs" role="alert">
                      <b>Sample Card:</b>
                      CC #: 4242 4242 4242 4242<br/>
                      CC Exp: any valid future date<br/>
                      CC CVC: any three digits<br/>
                      CC Zip: any five digits
                  </div>
                  <div class="card p-4 card-msgs invisible" id="messages" role="alert">
                    <h6><strong>Messages:</strong></h6>
                    <div id="messages" v-html="stripeMessages"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button class="btn btn-success" type="submit">Pay Now</button>
              <button class="btn btn-primary" @click="goBack">Back</button>
            </div>
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </form>
    <alert
      :show="isAlert"
      @click="doAlert('hide')"
      placement="top"
      duration="3000"
      type="danger"
      width="400px"
      dismissable
    >
      <span class="icon-info-circled alert-icon-float-left"></span>
      {{ alertMessage }}
    </alert>
    <div
      class="fade"
      :class="{ show: isLoading, 'd-none': !isLoading }"
      :show="isLoading"
    >
      <div
        id="exampleModalPopovers"
        class="modal fade"
        :class="{ show: isLoading }"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalPopoversLabel"
        style="display: block; padding-right: 15px"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalPopoversLabel">
                Do not navigate away!
              </h5>
            </div>
            <div class="modal-body">
              <b> Please wait...</b>
              <p>
                {{loadingData.message}}
              </p>
              <div class="progress">
                <div
                  role="progressbar"
                  class="
                    progress-bar progress-bar-success progress-bar-striped
                    active
                  "
                  :style="{width: loadingData.percent}"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade" :class="{ show: isLoading }"></div>
    </div>
  </div>
</template>

<script>
//publishable key
// import Stripe from 'stripe';
import { alert } from "vue-strap";
import { mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";
import { collection, addDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default {
  components: {
    alert: alert,
  },
  data() {
    return {
      test: {},
      stripe: "",
      cardElement: "",
      stripeMessages: "",
      isAlert: false,
      alertMessage: "Form Error. Please fix and re-submit payment.",
      isLoading: false,
      /// MAIN
      fbStorage: this.$store.state.fbStorage,
      fbDatabase: this.$store.state.fbDatabase,
      userData: this.$store.state.userData, //?not working using mapGetter
      tempUser: _.cloneDeep(this.$store.state.userData),
      modalStatus: [
        {
          id: 1,
          percent: '20%',
          message: 'Submitting payment details...'
        },
        {
          id: 2,
          percent: '80%',
          message: 'Your payment has been processed. Please wait patiently while we submit all of your data to the embassy.'
        },
      ],
      loadingData: {id: 0, percent: '0%', message:'Waiting on user...'}
    };
  },
  methods: {
    ...mapActions(["clearUserData"]),
    doAlert(action, msg) {
      /// action: show || hide
      if (action === "hide") {
        this.isAlert = false;
        this.alertMessage = "";
      } else {
        this.isAlert = true;
        this.alertMessage = msg;
        var that = this;
        setTimeout(function () {
          that.doAlert("hide");
        }, 3000);
      }
    },
    doLoading(action, index) {
      console.log("doLoading:" + index);
      /// action: show || hide
      if (action === 'hide') {
        this.isLoading = false;
        this.loadingData = {}
      } else {
        this.isLoading = true;
        this.loadingData = this.modalStatus[index];
      }
      // const elBody = document.getElementsByTagName("BODY")[0];
      // elBody.classList.add('modal-open');
    },
    addMessage(message) {
      const newMsg = "> " + message + "<br/>";
      this.stripeMessages += newMsg;
      console.log("StripeSampleDebug:", message);
    },
    setUpStripe() {
      ///TODO: add stripe data to the tempUser object
      this.tempUser.stripe = {
        paymentIntent: null,
        paymentEmail: null,
        date: moment().format(),
        verified: false,
        verified_date: null,
      };
      if (window.Stripe === undefined) {
        alert("Stripe V3 library not loaded inside checkout-component!");
        return;
      } else {
        const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY); //test-mode
        // const stripe = window.Stripe("pk_live_vHLFv4kPpls7bhiIaQBlEwur"); //live-mode
        this.stripe = stripe;

        const elements = this.stripe.elements();
        const style = {
          base: {
            color: "#32325d",
          },
        };

        const cardElement = elements.create("card", { style: style });
        cardElement.mount("#card-element");
        this.cardElement = cardElement;
        cardElement.on("change", ({ error }) => {
          let displayError = document.getElementById("card-errors");
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = "";
          }
        });
      } //end else

      // this.listenForErrors();
    },
    async stripeSubmit() {
      this.doLoading('show', 0);
      // Create PaymentIntent on the server
      const backendSubmitUrl = process.env.VUE_APP_BACKEND_URL + "/create-payment-intent";
      const { error: backendError, clientSecret } = await fetch(
        backendSubmitUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodType: ["card"],
            currency: "usd",
            uuid: this.tempUser.uuid,
          }),
        }
      ).then((res) => res.json());
      if (backendError) {
        this.addMessage(backendError.message);
        this.doAlert("show", backendError.message);
        this.doLoading('hide');
        return;
      }
      this.addMessage("PaymentIntent created!");

      // Tokenize and process payment => sends back confirmation
      const nameInput = document.querySelector("#paymentName");
      const emailInput = document.querySelector("#paymentEmail");
      const { error: stripeError, paymentIntent } =
        await this.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: this.cardElement,
            billing_details: {
              name: nameInput.value,
              email: emailInput.value,
            },
          },
        });
      if (stripeError) {
        this.doLoading('hide');
        this.addMessage(stripeError.message);
        this.doAlert(
          "show",
          stripeError.message + " Please fix and re-submit payment.."
        );
        return;
      }
      const successMsg =
        "PaymentIntent (" + paymentIntent.id + "): " + paymentIntent.status;
      this.tempUser.stripe.paymentIntent = paymentIntent.id;
      this.tempUser.stripe.paymentEmail = emailInput.value;
      this.addMessage(successMsg);
      this.chainSave();
    },
    goBack() {
      this.$store.state.visaStep = "app-visaform4";
    },
    async doSaveFile(mode) {
      let filename = "";
      let filedata = "";
      if (mode === "input") {
        filedata = this.userData.personal.photos.input;
        filename = this.userData.uuid + "_input.jpg";
      } else if (mode === "output") {
        filedata = this.userData.personal.photos.output;
        filename = this.userData.uuid + ".jpg";
      }

      const storage = this.fbStorage;
      const storageRef = ref(storage, "user_photos/" + filename);

      let docRef2;
      try {
        docRef2 = await uploadString(storageRef, filedata, "data_url");
        console.log("File saved with ID: ", docRef2.metadata.name);
        if (mode === "input") {
          this.uploadedPhoto1 = true;
          this.tempUser.personal.photos.input = filename;
        } else if (mode === "output") {
          this.uploadedPhoto2 = true;
          this.tempUser.personal.photos.output = filename;
        }
      } catch (e) {
        console.error("Error saving document: ", e);
      }

      return docRef2;
    },
    async doSaveData() {
      const db = this.fbDatabase;
      let docRef;
      try {
        docRef = await addDoc(collection(db, "users"), this.tempUser);
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      // return docRef;
      return true;
    },
    async chainSave() {
      this.doLoading('show', 1);
      let task1 = await this.doSaveFile("input");
      let task2 = await this.doSaveFile("output");
      this.uploadedData = await this.doSaveData();
      let task4 = await this.clearUserData();
      //showloading(false);

      this.$store.state.showThanks = true;
      this.$router.push({ path: "/thankyou", replace: true });
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.setUpStripe();
  },
  unmounted() {
    this.isLoading = false;
  },
};
</script>

<style>
.card-bkg {
  background-color: #efefef;
  padding: 1rem;
}
.card-msgs {
  background-color: #333;
  color: yellow;
}
</style>